$config-header-height: 4.5rem;
$progress-bar-height: 5px;

.wrapper {
  @for $i from 1 through 10 {
    > :nth-child(#{$i}) {
      .configuration-sticky-block {
        position: sticky;
        top: calc($config-header-height + var(--main-header-height) + calc(var(--main-alert-bar-height) * calc($i - 3)) + var(--spacing-sm));

        @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
          position: inherit;
        }
      }
    }
  }
}

.configuration-progress-bar {
  position: absolute;
  left: 0;
  bottom: -$progress-bar-height;
  height: $progress-bar-height;
  width: 100%;
  background-color: var(--color-grey);

  &__track {
    height: $progress-bar-height;
    background-color: var(--color-green);
  }
}

.configuration-main-title {
  margin-bottom: var(--spacing-md);
}

.toolbar-preview-box {
  height: 10rem;
  background-color: var(--color-grey-light);
}

.config-header {
  display: flex;
  justify-content: space-between;
  height: $config-header-height;
  padding: 0 var(--spacing-lg);
  color: var(--color-midnight);
  box-shadow: 0 1px 0 var(--color-grey);
  align-items: center;

  &__menu {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    margin-right: var(--spacing-sm);

    &__label {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);
      height: 100%;
      padding: 0 var(--spacing-md);
      font-size: $font-size-13;
      font-weight: $font-weight-bold;
      letter-spacing: 0.2pt;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
    }

    &__content {
      display: none;
      position: absolute;
      top: calc($config-header-height - var(--spacing-sm));
      background-color: var(--color-white);
      border-radius: var(--border-radius-md);
      box-shadow: 0 5px 20px -12px var(--color-midnight);
      min-width: 24rem;
      z-index: 16;
      overflow: hidden;

      ul li.config-header__menu__content__row {
        position: relative;
        display: flex;
        align-items: center;
        font-size: $font-size-14;
        color: var(--color-midnight);

        &:hover,
        &.config-header__menu__content__row--active {
          color: var(--color-green);
          background-color: var(--color-grey-light);

          .config-header__menu__content__row__link svg path {
            fill: var(--color-green);
          }
        }

        .config-header__menu__content__row__link {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 1.5rem 3rem;
          line-height: 1.5;
          text-decoration: none;
          cursor: pointer;

          span {
            display: flex;
          }

          i {
            margin: 0 0.5rem;
            font-size: $font-size-20;
          }

          svg {
            fill: currentColor;
            margin: 0 0.75rem;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    &:hover {
      .config-header__menu__label {
        color: var(--color-green);
      }

      .config-header__menu__content {
        display: block;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    &__label {
      display: inline-flex;
      align-items: center;
      color: var(--color-midnight);
      font-size: $font-size-14;
      font-weight: $font-weight-regular;

      i {
        margin-right: .25rem;
      }
    }

    .split-button,
    .inline-buttons button,
    .inline-buttons a {
      margin-left: 1rem;
      margin-right: 0;
    }

    .split-button {
      display: none;
    }

    .inline-buttons {
      display: inline-flex;

      a {
        text-decoration: none;
      }
    }
  }
}

/* RESPONSIVE */
@media #{$screen-s}, #{$screen-m} {
  .config-header {
    &__menu {
      &__label {
        padding: 0 1rem;
        font-size: $font-size-12;
      }
    }

    &__buttons {
      button.btn-big {
        padding: 1rem;
      }

      .split-button {
        display: inline-flex;
      }

      .inline-buttons {
        display: none;
      }
    }
  }
}

@media #{$screen-s} {
  .config-header {
    padding: 0 1rem;

    &__buttons {
      &__label {
        max-width: 8rem;
      }
    }
  }
}
